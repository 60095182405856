

















































































import { isEqual } from 'lodash'
import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins } from 'vue-property-decorator'

import DateInput from '@/components/_uikit/controls/DateInput.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerCoursesModule from '@/store/modules/manager/courses'
import { CourseLinkResource, CourseType, IManagerCoursesFilter, IManagerCoursesLinksFilterType } from '@/store/types'
import DictionaryModule from '@/store/modules/dictionary'
import { tableFooterOptions } from '@/utils/constants'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    DateInput,
    Select,
    TableFooter,
    Tag,
    TextInput,
  },
})
export default class CoursesLinksTable extends Mixins(NotifyMixin) {
  private linkBaseUrl = `${process.env.VUE_APP_BACKEND_ENDPOINT}/c/`
  private footerOptions = tableFooterOptions

  private get filter () {
    return ManagerCoursesModule.coursesLinksFilter
  }

  private set filter (filter: IManagerCoursesFilter) {
    if (!isEqual(filter, this.filter) || this.links.length === 0) {
      ManagerCoursesModule.setCoursesLinksFilter(filter)
      this.fetchCoursesLinks()
    }
  }

  private get headers () {
    return [
      { sortable: false, text: '#', value: 'id' },
      { text: 'Название', value: 'course.title' },
      { text: 'Тип группы', value: 'type' },
      { cellClass: 'cell-width-20', text: 'Даты проведения', value: 'dates' },
      { text: 'Ссылка', value: 'link' },
    ]
  }

  private get subjects () {
    return DictionaryModule.subjects
  }

  private get groupTypes () {
    return DictionaryModule.groupTypes
  }

  private get links () {
    return ManagerCoursesModule.coursesLinks.data
  }

  private get pagination () {
    return ManagerCoursesModule.coursesLinks.meta
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private isSpecial (link: CourseLinkResource) {
    return link.course.type.value === CourseType.SPECIAL
  }

  @Bind
  @Debounce(300)
  private fetchCoursesLinks () {
    ManagerCoursesModule.fetchCoursesLinks()
      .catch(this.notifyError)
  }

  private handleFilter (field: IManagerCoursesLinksFilterType, value: never) {
    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
  }
}
