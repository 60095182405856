








import { MetaInfo } from 'vue-meta'
import { Vue, Component } from 'vue-property-decorator'

import CoursesLinksTable from '@/components/tables/CoursesLinksTable.vue'

@Component({
  components: { CoursesLinksTable },
})
export default class CoursesLinks extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'Ссылки на покупку курсов',
    }
  }
}
